export var SHA_1_HASH = { name: "SHA-1" };
export var SHA_1_HMAC_ALGO = {
    name: "HMAC",
    hash: SHA_1_HASH,
};
export var EMPTY_DATA_SHA_1 = new Uint8Array([
    218,
    57,
    163,
    238,
    94,
    107,
    75,
    13,
    50,
    85,
    191,
    239,
    149,
    96,
    24,
    144,
    175,
    216,
    7,
    9,
]);
